import moment, { type Moment } from "moment";
import { type QueryFilter, type QueryFilterCollection } from "./types";
import { Operator } from "./apollo-utility-helpers";
import { hasValue } from "../common-helpers";
import { SALES_STATUS } from "../portfolio/portfolio-sales-helpers";
import VueRouter, { type Route } from "vue-router";
import type { UrlParam } from "~/composables/useUrlParam";
import {
  AssetManagementCustomFieldType,
  AssetManagementPropertyFlagValue,
  AssetManagementPropertySubType,
  AssetManagementTenancyFlagValue,
} from "~/graphql/generated/graphql";
import type { TenanciesCustomFieldKey } from "../user-preferences-helpers";
import { parseCustomFieldKey } from "../portfolio/portfolio-tenancy-helpers";
import type { CustomField } from "../portfolio/portfolio-custom-field-helpers";
import { ComputedRef } from "vue";
import { TenancyStatus, TenantStatus } from "~/pages/portfolio/components/ViewTenancies/helpers";

export const PropertyDecomissionedFilter = function () {
  return {
    or: true,
    matches: [{ administration_end_date: [Operator.GreaterThan, moment().format("YYYY-MM-DD")] }, { administration_end_date: [Operator.Equals, null] }],
  };
};

export const PropertySubTypeFilter = (): QueryFilter => ({ matches: [{ sub_type: [Operator.Equals, AssetManagementPropertySubType.Property] }] });
export const CompanySubTypeFilter = (): QueryFilter => ({ matches: [{ sub_type: [Operator.Equals, AssetManagementPropertySubType.Company] }] });
export const propertyPortfolioFilter = (variables: ComputedRef<{ portfolioId?: string }>) => () =>
  variables.value.portfolioId ? { matches: [{ portfolio_id: variables.value.portfolioId }] } : null;
export const propertyExcludeIdsFilter = (variables: ComputedRef<{ excludeIds?: string[] }>) => () => {
  const excludeIds = variables.value.excludeIds;
  if (!excludeIds?.length) return null;

  const filter: QueryFilter = { matches: [{ id: [Operator.In, variables.value.excludeIds, true] }] };

  if (excludeIds.includes("companies")) filter.matches.push({ sub_type: [Operator.Equals, AssetManagementPropertySubType.Company, true] });
  if (excludeIds.includes("properties")) filter.matches.push({ sub_type: [Operator.Equals, AssetManagementPropertySubType.Property, true] });

  return filter;
};

export const TenancyDecomissionedFilter = function () {
  return {
    or: true,
    matches: [{ end_date: [Operator.GreaterThan, moment().format("YYYY-MM-DD")] }, { end_date: [Operator.Equals, null] }],
  };
};

export const TenancySoldFilter = function () {
  return {
    matches: [{ status: [Operator.In, [SALES_STATUS.COMPLETED, SALES_STATUS.HANDED_OVER, SALES_STATUS.REMOVED], true /** Inverse flag */] }],
  };
};

export const TenancyForSaleFilter = function () {
  return {
    matches: [
      {
        status: [
          Operator.In,
          [
            SALES_STATUS.FOR_SALE,
            SALES_STATUS.OFFER_ACCEPTED,
            SALES_STATUS.SPA_BINDING,
            SALES_STATUS.COMPLETED,
            SALES_STATUS.HANDED_OVER,
            SALES_STATUS.REMOVED,
          ],
          true /** Inverse flag */,
        ],
      },
    ],
  };
};

export const budgetValidRangeFilter = (variables: ComputedRef<{ from?: Moment; to?: Moment }>) => () =>
  variables.value.from && variables.value.to
    ? {
        matches: [
          { valid_from: [Operator.GreaterThanOrEqual, moment.utc(variables.value.from).startOf("year").toISOString()] },
          { valid_to: [Operator.LessThanOrEqual, moment.utc(variables.value.to).endOf("year").toISOString()] },
        ],
      }
    : null;

export type DaysOnMarketValueConfig = {
  id: number;
  dayMin?: number;
  dayMax?: number;
};

export const daysOnMarketValues: readonly DaysOnMarketValueConfig[] = [
  {
    id: 1,
    dayMin: 0,
    dayMax: 14,
  },
  {
    id: 2,
    dayMin: 14,
    dayMax: 30,
  },
  {
    id: 3,
    dayMin: 30,
  },
];

export type DaysOnMarketRange = {
  dayMin?: number;
  dayMax?: number;
};

export const DaysOnMarketFilter = function (daysOnMarketMin?: number, daysOnMarketMax?: number) {
  const matches = [];

  if (daysOnMarketMin) {
    matches.push({ days_on_market: [Operator.GreaterThanOrEqual, daysOnMarketMin] });
  }

  if (daysOnMarketMax) {
    matches.push({ days_on_market: [Operator.LessThanOrEqual, daysOnMarketMax] });
  }

  return {
    matches: matches,
  };
};

export const CurrentPlannedRentFilter = function () {
  return {
    matches: [
      {
        date_scoped_relevance_rank: 1,
        rent_type: "RENT",
      },
    ],
  };
};

export const ValuationFilter = function (dateStart: string, dateEnd: string) {
  return {
    matches: [
      {
        entry_date: [Operator.GreaterThanOrEqual, dateStart],
      },
      {
        entry_date: [Operator.LessThanOrEqual, dateEnd],
      },
      {
        recursive_category_type: [Operator.In, ["property_value", "PROPETY_VALUE"]],
      },
    ],
  };
};

export const vacantUnitFilter = (date?: string): QueryFilter => ({
  matches: [{ current_tenant_is_ghost: date ? { lte: date, value: true } : true }],
});

export const rerentFilter = (): QueryFilter => ({
  matches: [{ ready_for_rental: true }],
});

export const tenanciesSearchFilter = (searchTerm: string): QueryFilter => ({
  or: true,
  matches: [
    { name: [Operator.ContainsCaseInsensitive, searchTerm] },
    { external_id: [Operator.ContainsCaseInsensitive, searchTerm] },
    { current_tenant_name: [Operator.ContainsCaseInsensitive, searchTerm] },
  ],
});

export const tenancyTypeFilter = (tenancyTypes: string[]): QueryFilter => ({
  matches: [{ tenancy_type: [Operator.In, tenancyTypes] }],
});

export const rentRegulationFilter = (rentRegulationPrinciples: string[]): QueryFilter => ({
  matches: [{ rent_regulation_principle: [Operator.In, rentRegulationPrinciples] }],
});

export const portfolioIdsFilter = (portfolioId: string[]): QueryFilter => ({
  matches: [{ portfolio_id: [Operator.In, portfolioId] }],
});

export const propertyIdsFilter = (propertyId: string[]): QueryFilter => ({
  matches: [{ property_id: [Operator.In, propertyId] }],
});

export const arrearsFilter = (value: number, selectedDate: string | undefined): QueryFilter => ({
  matches: [{ current_tenant_balance: [Operator.GreaterThanOrEqual, selectedDate ? { lte: moment.utc(selectedDate).toISOString(), value } : value] }],
});

export const assignedToFilter = (value: string[]): QueryFilter => ({
  matches: [{ responsible_id: [Operator.In, value] }],
});

const rangeFilter = (field: string, from?: string | number, to?: string | number, customFieldId?: string) => {
  const filter: QueryFilter = { matches: [] };

  if (hasValue(from)) filter.matches.push({ [field]: [Operator.GreaterThanOrEqual, customFieldId ? { id: customFieldId, value: from } : from] });

  if (hasValue(to)) filter.matches.push({ [field]: [Operator.LessThanOrEqual, customFieldId ? { id: customFieldId, value: to } : to] });

  return filter;
};

export const dateFilterStringMap = {
  today: "date_func,descriptor:today",
  tomorrow: "date_func,descriptor:tomorrow",
};

export const irrevocableFilterStringMap: { [k in IrrevocableOptionKey]: { [k in (typeof Operator)[keyof typeof Operator]]?: string | null } } = {
  none: { eq: null },
  expired: { lt: "date_func,descriptor:today" },
  "<30": { gte: "date_func,descriptor:today", lt: "date_func,descriptor:today,modify:+30d" },
  "<90": { gte: "date_func,descriptor:today", lt: "date_func,descriptor:today,modify:+90d" },
  "<365": { gte: "date_func,descriptor:today", lt: "date_func,descriptor:today,modify:+365d" },
  "1y...2y": { gte: "date_func,descriptor:today,modify:+365d", lt: "date_func,descriptor:today,modify:+730d" },
  "2y...3y": { gte: "date_func,descriptor:today,modify:+730d", lt: "date_func,descriptor:today,modify:+1095d" },
  "3y...": { gte: "date_func,descriptor:today,modify:+1095d" },
  "365+": { gte: "date_func,descriptor:today,modify:+365d" },
};

const mapDateFilterString = (dateFilterString: string | undefined, selectedDate: string | undefined) => {
  if (selectedDate) {
    switch (dateFilterString) {
      case "today":
        return moment.utc(selectedDate).toISOString();
      case "tomorrow":
        return moment.utc(selectedDate).add(1, "day").toISOString();
      default:
        return dateFilterString;
    }
  }

  switch (dateFilterString) {
    case "today":
    case "tomorrow":
      return dateFilterStringMap[dateFilterString];
    default:
      return dateFilterString;
  }
};

export const tenancyAdminStartFilter = (from: string | undefined, to: string | undefined, date: string | undefined): QueryFilter => {
  const filter = rangeFilter("start_date", mapDateFilterString(from, date), mapDateFilterString(to, date));

  if (hasValue(to) && !hasValue(from)) {
    filter.matches.push({ start_date: [Operator.Equals, null] });
    filter.or = true;
  }

  return filter;
};

export const tenancyAdminEndFilter = (from: string | undefined, to: string | undefined, date: string | undefined): QueryFilter => {
  const filter = rangeFilter("end_date", mapDateFilterString(from, date), mapDateFilterString(to, date));

  if (hasValue(from) && !hasValue(to)) {
    filter.matches.push({ end_date: [Operator.Equals, null] });
    filter.or = true;
  }

  return filter;
};

export const propertyAdminStartFilter = (from: string | undefined, to: string | undefined, date: string | undefined): QueryFilter => {
  const filter = rangeFilter("property_administration_start_date", mapDateFilterString(from, date), mapDateFilterString(to, date));

  if (hasValue(to) && !hasValue(from)) {
    filter.matches.push({ property_administration_start_date: [Operator.Equals, null] });
    filter.or = true;
  }

  return filter;
};

export const propertyAdminEndFilter = (from: string | undefined, to: string | undefined, date: string | undefined): QueryFilter => {
  const filter = rangeFilter("property_administration_end_date", mapDateFilterString(from, date), mapDateFilterString(to, date));

  if (hasValue(from) && !hasValue(to)) {
    filter.matches.push({ property_administration_end_date: [Operator.Equals, null] });
    filter.or = true;
  }

  return filter;
};

export const getTenancyDefaultUrlQuery = (defaultFilterId?: string) =>
  defaultFilterId
    ? { filterId: defaultFilterId }
    : {
        startTo: "today",
        endFrom: "tomorrow",
        propertyAdminStartTo: "today",
        propertyAdminEndFrom: "tomorrow",
        salesStatus: [
          SALES_STATUS.FOR_SALE,
          SALES_STATUS.NOT_FOR_SALE,
          SALES_STATUS.OFFER_ACCEPTED,
          SALES_STATUS.PREPARING_FOR_SALE,
          SALES_STATUS.SPA_BINDING,
        ].join(","),
      };

export const zipRangeFilter = (from?: number, to?: number): QueryFilter => rangeFilter("property_zip_code", from, to);

export const areaRangeFilter = (from?: number, to?: number): QueryFilter => rangeFilter("area", from, to);

export type IrrevocableOptionKey = "none" | "expired" | "<30" | "<90" | "<365" | "1y...2y" | "2y...3y" | "3y..." | "365+";

export type IrrevocableFilterField = "current_tenant_irrevocable_until_tenant" | "current_tenant_irrevocable_until_landlord";

export const irrevocableFilter = (field: IrrevocableFilterField, options: IrrevocableOptionKey[], selectedDate: string | undefined): QueryFilter[] => {
  const from: QueryFilter = { matches: [], or: true };
  const to: QueryFilter = { matches: [], or: true };

  for (let o = 0; o < options.length; o++) {
    const option = options[o];

    const filters = irrevocableFilterStringMap[option];

    let key: (typeof Operator)[keyof typeof Operator];

    const count = Object.keys(filters).length;

    const getSelectedDateValue = (key: (typeof Operator)[keyof typeof Operator]) => {
      const today = moment.utc(selectedDate);

      switch (option) {
        case "none":
          return null;
        case "expired":
          break;
        case "365+":
          today.add(365, "days");
          break;
        case "1y...2y":
          if (key === "gte") today.add(365, "days");
          if (key === "lt") today.add(365 * 2, "days");
          break;
        case "2y...3y":
          if (key === "gte") today.add(365 * 2, "days");
          if (key === "lt") today.add(365 * 3, "days");
          break;
        case "3y...":
          today.add(365 * 3, "days");
          break;
        default:
          if (key === "lt") {
            switch (option) {
              case "<30":
                today.add(30, "days");
                break;
              case "<90":
                today.add(90, "days");
                break;
              case "<365":
                today.add(365, "days");
                break;
            }
          }
      }

      return today.toISOString();
    };

    for (key in filters) {
      const valueStr = selectedDate ? getSelectedDateValue(key) : (filters[key] as string | null);
      const value = selectedDate ? { lte: moment.utc(selectedDate).toISOString(), value: valueStr } : valueStr;

      if (count === 1) {
        from.matches.push({ [field]: [key, value] });
        to.matches.push({ [field]: [key, value] });
      } else {
        switch (key) {
          case "gte":
            from.matches.push({ [field]: [key, value] });
            break;
          case "lt":
            to.matches.push({ [field]: [key, value] });
            break;
        }
      }
    }
  }

  return [from, to];
};

export const customFieldFilter = (field: CustomField, value: string | undefined): QueryFilter => {
  switch (field.fieldType) {
    case AssetManagementCustomFieldType.Number:
      const [from, to] = value?.split("...") as [string | undefined, string | undefined];

      return rangeFilter("custom_field", parseFloat(from as string) || undefined, parseFloat(to as string) || undefined, field.id);
    case AssetManagementCustomFieldType.Boolean:
      return {
        matches: [{ custom_field: [Operator.Equals, { id: field.id, value: value === "true" ? true : null }] }],
      };
    case AssetManagementCustomFieldType.Option:
      const selectedOptions = value?.split(",") ?? [];

      return {
        matches: [{ custom_field: [Operator.In, { id: field.id, value: selectedOptions }] }],
      };
    default:
      return {
        matches: [{ custom_field: [Operator.ContainsCaseInsensitive, { id: field.id, value }] }],
      };
  }
};

export type OperatorField<T = any> = [(typeof Operator)[keyof typeof Operator], T, boolean | undefined];
export type OperatorObject<T> = { operator: (typeof Operator)[keyof typeof Operator]; value: T; inverse: boolean };

export const asOperatorField = <T, K extends string = string>(match: { [k in K]: OperatorField<T> } | undefined, key: K): OperatorObject<T> | undefined =>
  match ? { operator: match[key][0], value: match[key][1], inverse: !!match[key][2] } : undefined;

export const findFieldInGroup = <T>(group: QueryFilter, key: string) => {
  for (let i = 0; i < group.matches.length; i++) if (group.matches[i][key]) return asOperatorField<T>(group.matches[i], key);
};

export const iterateFilterCollectionKeys = (
  collection: QueryFilterCollection,
  handler: <T extends string>(key: T, group: QueryFilter, match: { [k in T]: any }) => void
) => {
  for (let i = 0; i < collection.groups.length; i++) {
    const group = collection.groups[i];

    for (let j = 0; j < group.matches.length; j++) {
      const match = group.matches[j];

      Object.keys(match).forEach((key) => handler(key, group, match));
    }
  }
};

export const excludeTenancyFromResultsFilter = (): QueryFilter => ({
  matches: [{ excludes_flag: [Operator.In, [AssetManagementTenancyFlagValue.ExcludeFromResults]] }],
});

export const excludePropertyFromResultsFilter = (): QueryFilter => ({
  matches: [{ excludes_flag: [Operator.In, [AssetManagementPropertyFlagValue.ExcludeFromResults]] }],
});

export const tenancyStatusFilter = (statuses: TenancyStatus[]) => {
  const filter: QueryFilter = { matches: [], or: false };

  if (statuses.length === 1) {
    const status = statuses[0];
    switch (status) {
      case "vacant":
        filter.matches.push({ current_tenant_is_ghost: [Operator.Equals, true] });
        break;
      case "occupiedNotTerminated":
      case "occupiedTerminated":
        filter.matches.push({
          current_tenant_is_ghost: [Operator.Equals, false],
          current_tenant_eviction_date: [Operator.Equals, null, status === "occupiedTerminated"],
        });
        break;
    }
  } else if (statuses.includes("vacant") && statuses.length === 2) {
    filter.matches.push({
      current_tenant_is_ghost: [Operator.Equals, true],
      current_tenant_eviction_date: [Operator.Equals, null, statuses.includes("occupiedTerminated")],
    });
    filter.or = true;
  } else if (statuses.length === 2) {
    filter.matches.push({ current_tenant_is_ghost: [Operator.Equals, false] });
  } else if (statuses.length === 3) {
    filter.matches.push({ current_tenant_is_ghost: [Operator.Equals, null, true] });
  }

  return filter;
};

export const tenantUpcomingFilter = (upcomingTenant: TenantStatus) => ({
  matches: [{ has_upcoming_tenant: [Operator.Equals, upcomingTenant === "upcomingTenant"] }],
});
